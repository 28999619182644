.form {
    box-shadow: 0.05rem 0.05rem 0.1rem 0.1rem var(--light-primary-shadow-color);
    border-radius: 2.5%;
    padding: 1rem!important;
    width: 85%;
    height: 100%;
    @include desktop {
        width: 100%;
        height: 100%;
      }
}

.form-container {
    width: 100%;
}