// Overriding colors of the AwesomeSlider library
.awssld__content{
    background-color: transparent!important;
    // display: flex!important;
  }
 

.awssld__bullets{
&--active {
    background-color: var(--secondary-theme-color)!important;

    }
}

.awssld__bullets{
--control-bullet-color: var(--secondary-theme-color)!important;
--control-bullet-active-color: var(--secondary-theme-color)!important;
--organic-arrow-color: green!important;
}

.awssld__controls{
visibility: visible!important;    
}

.awssld__controls__arrow-right::before{
background-color: var(--secondary-theme-color);
}

.awssld__controls__arrow-right::after{
background-color: var(--secondary-theme-color);
}

.awssld__controls__arrow-left::before{
background-color: var(--secondary-theme-color);
}

.awssld__controls__arrow-left::after{
background-color: var(--secondary-theme-color);
}

.awssld__timer{
    --timer-height: 0.5rem!important;
    --timer-background-color: var(--secondary-theme-color)!important;
}

.slider-contain{
    @include desktop {
        padding-top: 3rem;
     }
}