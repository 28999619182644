.footer {
  height: auto;
  padding-top: var(--section-padding);
  margin-bottom: -2rem;
  // padding-bottom: 1rem;
  background: var(--secondary-theme-color);
  background-color: var(--secondary-theme-color);
  margin-bottom: -2rem;
  &--scroll {
    background: linear-gradient(
      90deg,
      var(--primary-gradient-color) 0%,
      var(--primary-highlight-color) 35%,
      var(--secondary-gradient-color) 100%
    );
    // border-top: solid;
    // border-color: var(--primary-highlight-color);
  }

  &--copyright-scroll {
    background: linear-gradient(
      90deg,
      var(--primary-gradient-color) 0%,
      var(--primary-highlight-color) 35%,
      var(--secondary-gradient-color) 100%
    );
    // border-top: solid;
    // border-color: var(--primary-highlight-color);
  }

  &__logo {
    display: block;
    width: 7rem;

    @include desktop {
      width: 14rem;
    }
  }
}
