.nav {
  --padding-nav: calc(var(--section-padding) * 0.75);
  padding-top: var(--padding-nav);
  padding-bottom: var(--padding-nav);
  transition: padding 0.25s;

  &--scroll {
    --padding-nav: calc(var(--section-padding) * 0.5);
  }

  &__logo {
    display: block;
    width: 5rem;
    @include desktop {
      width: 8rem;
    }
  }

  &__l-logo {
    display: block;
    width: 10rem;
    @include desktop {
      width: 16rem;
      margin: 2rem;
    }
  }
}
