.header {
    font-family: var(--primary-font-family);
    --translate-header: 0!important;
    position: fixed;
    z-index: 25;
    top: 0;
    left: 0;
    width: 100%;
    transition: left 0.25s, background-color 0.25s, box-shadow 0.25s;
    left: var(--translate-header);
    background-color: var(--primary-theme-color);

    border-bottom: solid;
        border-color: var(--secondary-theme-color);
    &--move-left {
        --translate-header: -50%;
    }

    &--move-right {
        --translate-header: 50%;
    }

    &--scroll {
        background: linear-gradient(90deg, var(--primary-gradient-color) 0%, var(--primary-highlight-color) 35%, var(--secondary-gradient-color) 100%);
        border-bottom: solid;
        border-color: var(--secondary-theme-color);
      }
}

.panel-header {
    font-family: var(--primary-font-family);
    --translate-header: 0;
    position: fixed;    
    z-index: 50;
    top: 0;
    left: 0;
    transition: left .5s, background-color 0.25s, box-shadow 0.25s;
    left: var(--translate-header);
    // background-color: var(--secondary-theme-color);
    
    // border-bottom: solid;
    //     border-color: var(--secondary-theme-color);
    &--move-left {
        --translate-header: -50%!important;
        // position: static;
    }

    &--move-right {
        --translate-header: 50%!important;
    }

    &--scroll {
        background-color: var(--secondary-theme-color);

        border-bottom: solid;
        border-color: var(--secondary-theme-color);
      }
}


.header-background {
    background: linear-gradient(90deg, var(--primary-gradient-color) 0%, var(--primary-highlight-color) 35%, var(--secondary-gradient-color) 100%);
}