.banner-img {
  opacity: 0.85;
  width: 100%;
  height: 100%;

  @include desktop {
    // width:100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.banner-container {
  // position: relative;
  // display: inline-block;
  // width: 100%;
  // height: 100%;
  display: block;
  height: 20vh;
  @include desktop {
    // width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120vh;
  }
}

.banner-text {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 75%;
  background-color: var(--light-primary-shadow-color);
  border-radius: 1rem;
  padding: 0.75rem;
  &--content {
    display: none;
    @include desktop {
      display: block;
      top: 75%;
      padding: 1rem;
    }
  }
}

.overlay-img {
  // background: gray;
  // overflow: hidden;
}

.rotate-img {
  width: 256px;
  border-radius: 50%;

  @include desktop {
    width: 352px;
  }
}

.circular-img {
  border-radius: 50%;
  width: 172px;
  height: auto;
}

.regular-img {
  width: 312px;
  border-radius: 10px;

  @include desktop {
    width: 512px;
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.regular-img.visible {
  opacity: 1;
  transform: translate(0, 0);
}

.news-img {
  width: 256px;
  border-radius: 15px;

  @include desktop {
    width: 512px;
  }
}

.xs-img {
  width: 128px;
  border-radius: 5%;

  @include desktop {
    width: 128px;
  }
}

.father-container {
  max-width: 1320px; /* Matches Bootstrap container */
  position: relative;
}

/* Top-right image */
#objectives-top-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 128px;
  border-radius: 5%;
  display: none;

  @media (min-width: 992px) {
    /* Bootstrap desktop breakpoint */
    width: 250px;
    display: inline;
  }
}

/* Bottom-left image */
#objectives-bottom-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200px;
  border-radius: 5%;
  display: none;

  @media (min-width: 992px) {
    /* Bootstrap desktop breakpoint */
    width: 400px;
    display: inline;
  }
}

.big-img {
  width: 20rem;
  border-radius: 5%;

  @include desktop {
    width: 36rem;
  }
}

.huge-img {
  width: 26rem;
  border-radius: 2rem;

  @include desktop {
    width: 48rem;
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.gallery-img {
  width: 30rem;

  @include desktop {
    width: 50rem;
  }
}

.logo-img {
  width: 6rem;
  @include desktop {
    width: 12rem;
  }
}

.logo-institution {
  width: 12rem;
  @include desktop {
    width: 16rem;
  }
}

.fade-animation {
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
