@mixin desktop {
    @media (min-width: 768px) {
      @content;
    }
  }

  @mixin tablet {
    @media (min-width: 640px) {
      @content;
    }
  }