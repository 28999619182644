.panel-container {
  min-height: 100vh;
  transition: margin-left 0.5s;
  margin-left: 0rem !important;

  &--full {
    margin-left: 7rem !important;
    transition: margin-left 0.5s;

    @include desktop {
      margin-left: 15rem !important;
      transition: margin-left 0.5s;
    }
  }

  &--sm {
    min-height: 100vh;
    @include desktop {
      min-height: 60vh;
    }
  }
}
