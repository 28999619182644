.page-link.active, .active > .page-link {
    background-color: var(--secondary-highlight-color)!important;
    border: none!important;
    color: var(--primary-text-color)!important;
    &:hover {
        color: var(--primary-text-color)!important;
      }
}

.page-link{
    color: var(--secondary-highlight-color)!important;

    &:hover {
        color: var(--secondary-highlight-color)!important;
      }

}

.pagination {
    --bs-pagination-focus-box-shadow: none!important;
    --bs-pagination-font-size: 1.25rem!important;
}

.page-item {
    font-size: 2rem!important;
}