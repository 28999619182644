.link {
  color: var(--primary-text-color);
  text-decoration: none;
  font-size: 1rem;
  &:hover {
    color: var(--primary-text-color);
    text-decoration: underline;
    cursor: pointer;
  }

  &__xs {
    font-size: 0.75rem;
    color: var(--primary-text-color);
    &:hover {
      color: var(--primary-theme-color);
    }
  }

  @include desktop {
    font-size: 1.25rem;
    &__xs {
      font-size: 1rem;
      color: var(--primary-text-color);
    }
  }
}
.title-link-download {
  text-decoration: none;
  font-size: 1rem !important;
  position: relative;
  border: none;
  font-family: var(--bold-font-family) !important;
  color: var(--secondary-text-color);
  @include desktop {
    font-size: 1.5rem !important;
  }

  &:hover {
    color: var(--secondary-highlight-color) !important;
    cursor: pointer;
  }
}

.link-download {
  text-decoration: none;
  font-size: 0.75rem;
  position: relative;
  border: none;

  color: var(--primary-text-color);
  @include desktop {
    font-size: 1rem;
  }

  &:hover {
    color: var(--secondary-highlight-color) !important;
    cursor: pointer;
  }
}

.link-social-media {
  text-decoration: none;
  font-size: 1rem;
  position: relative;
  border: none;

  color: var(--secondary-text-color);
  @include desktop {
    font-size: 1rem;
  }

  &:hover {
    color: var(--secondary-text-color);
  }
}

.secondary-link-social-media {
  color: var(--primary-text-color);
}
