.icon {
    display: flex !important;
    background-color: transparent;
    border: 0;
    color: var(--primary-text-color);
    font-size: 2rem;
    padding: 0;
    filter: brightness(0.75);
    cursor: pointer;
    transition: filter 0.25s, color 0.25s;
    height: 2rem;
    
    @include desktop {
      font-size: 2rem;
    }

    &:focus, &:active {
        outline: none
      }

    &:hover {
        filter: brightness(1);
        color: var(--secondary-text-color);

      }

      &--secondary{
        color: var(--primary-text-color);
        font-size: 1.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      &--xl{
        font-size: 2rem;
        margin-right: 0.5rem; 
        margin-left: 0.5rem;
      }
      
    &--close {
      position: absolute;
      top: var(--padding-nav);
      right: var(--container-padding);
      transition: filter 0.25s, color 0.25s, top 0.25s;
    }
    
    &--display{
      display:block!important;
    }

    &--hide{
      visibility: hidden!important;
    }

    &--menu,
    &--close {
      @include desktop {
        display: none!important;
      }
    }

    &--social-media{
      @include desktop {
        font-size: 2rem;
      }
      font-size: 1.3rem;
      color: var(--primary-text-color);

      &:hover {
        filter: brightness(1);
        color: var(--primary-theme-color);;
      }
    }
  }

  .panel-icon {
    display: flex !important;
    background-color: transparent;
    border: 0;
    color: var(--primary-text-color);
    font-size: 2rem;
    padding: 0;
    filter: brightness(0.75);
    cursor: pointer;
    transition: filter 0.25s, color 0.25s;
    height: 2rem;
    
    @include desktop {
      font-size: 2rem;
    }

    &:focus, &:active {
        outline: none
      }

    &:hover {
        filter: brightness(1);
      }
      
    &--close {
      position: absolute;
      top: var(--padding-nav);
      right: var(--container-padding);
      transition: filter 0.25s, color 0.25s, top 0.25s;
      visibility: hidden!important;

    }
    
    &--display{
      display:block!important;
    }

    // &--hide{
    // }

    &--menu,
    &--close {
      @include desktop {
        display: none!important;
      }
    }

  }

  .small-icon {
    color: var(--primary-text-color);
    font-size: 1.5rem;
  }

  .primary-small-icon {
    color: var(--secondary-text-color);
    font-size: 1.5rem;
    background-color: transparent;
    border: 0;
  }

  .secondary-small-icon {
    color: var(--secondary-highlight-color);
    font-size: 2rem;
    background-color: transparent;
    border: 0;
    &:hover {
      filter: brightness(1);
      color: var(--secondary-text-color);;
    }
  }

  .secondary-large-icon {
    color: var(--secondary-highlight-color);
    font-size: 2.5rem;
    background-color: transparent;
    border: 0;
  }

  .big-icon {
    color: var(--secondary-highlight-color);
    font-size: 5rem;
  }

  .huge-icon {
    color: var(--secondary-highlight-color);
    font-size: 7rem;
  }