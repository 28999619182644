.d-flex {
  display: flex;

  &--center {
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }

  &--right {
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: right;
    text-align: right;
  }

  &--left {
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: left;
    text-align: left;
  }

  &--direction-responsive {
    flex-direction: column;
    @include desktop {
      flex-direction: row;
    }
  }

  &--direction-row {
    flex-direction: row;
  }

  &--direction-column {
    flex-direction: column;
  }

  &--space-between {
    align-items: center;
    justify-content: space-between;
  }
}

//Replacing container class of booststrap.
.container {
  max-width: var(--container-max-width);
  margin: 0 auto !important;
  padding-left: var(--container-padding) !important;
  padding-right: var(--container-padding) !important;

  &--sm {
    padding-left: calc(var(--container-padding) * 0.75) !important;
    padding-right: calc(var(--container-padding) * 0.75) !important;
    max-width: none !important;
  }

  &--xl {
    max-width: 100% !important;
  }
}

.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.row > * {
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100%;
}

.row-padding {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.img-section {
  background: url("../../assets/img/home/grupal_6.webp");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  height: 40rem;
  width: 100%;
}

.course-section {
  background: url("../../assets/img/course/course.webp");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  height: 32rem;
  width: 100%;
}

.koica-section {
  background: url("../../assets/img/koica/banner.webp");
  background-size: contain; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 12rem;
  width: auto;
  @include desktop {
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
    height: 32rem;
    width: 100%;
  }
}

.news-section {
  background: url("../../assets/img/news/news.webp");
  background-size: cover; /* <------ */
  background-repeat: repeat;
  background-position: center center;
  min-height: 12rem;
  width: auto;
  @include desktop {
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
    height: 20rem;
    width: 100%;
  }
}

.wave-section {
  @include desktop {
    margin-top: -5rem;
    margin-bottom: -5rem;
    z-index: 0;
  }
}

.wave {
  fill: var(--secondary-theme-color);
}

.auto-margin {
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.overflow-y {
  overflow-y: hidden;
}

.h-75-vh {
  height: 75vh;
}

.h-100-vh {
  height: 98vh;
}

.news-section {
  background: url("../../assets/img/news/news.webp");
  background-size: cover; /* <------ */
  background-repeat: repeat;
  background-position: center center;
  min-height: 12rem;
  width: auto;
  @include desktop {
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
    height: 20rem;
    width: 100%;
  }
}

.separator {
  border: 0.5rem solid;
  margin-top: -20px;
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  color: var(--secondary-theme-color);
}
