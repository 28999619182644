@import "./mixins/mixins";
@import "./common/animation";
@import "./common/header";
@import "./common/nav";
@import "./common/list";
@import "./common/layout";
@import "./common/sidebar";
@import "./common/icon";
@import "./common/utils";
@import "./common/form";
@import "./common/countdown";
@import "./common/button";
@import "./common/footer";
@import "./common/text";
@import "./common/link";
@import "./common/slider";
@import "./common/image";
@import "./common/panel";
@import "./common/textarea";
@import "./common/row";
@import "./common/table";
@import "./common/pagination";
@import "./common/modal";
@import "./common/select";

// @import './modules/common/alert';
@import "./common/map";
@import "./common/card";
// @import './modules/common/loader';
// @import './modules/common/ribbon';

/* Add this CSS to your global stylesheet or a relevant CSS module */
.swal2-container {
  z-index: 1300 !important; /* Ensure this value is higher than your Dialog's z-index */
}
