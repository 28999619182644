.label-secondary-text {
  font-size: 1.25rem;
  font-family: var(--primary-font-family);
  color: var(--secondary-text-color);
}

.regular-highlight-text {
  font-size: 1rem !important;
  font-family: var(--primary-font-family) !important;
  color: var(--primary-highlight-color) !important;
  @include desktop {
    font-size: 1.5rem !important;
  }
}

.paragraph-highlight-text {
  font-size: 1rem;
  font-family: var(--primary-font-family);
  color: var(--secondary-highlight-color);
  margin: auto;
  @include desktop {
    font-size: 1rem;
  }
}

.subtitle-highlight-text {
  font-size: 1rem;
  font-family: var(--italic-font-family);
  color: var(--secondary-highlight-color);
  margin: auto;
  @include desktop {
    font-size: 1.5rem;
  }
}

.title-highlight-text {
  font-size: 2rem;
  font-family: var(--italic-font-family);
  color: var(--primary-highlight-color);
}

.font-size-xs {
  font-size: 0.75rem;
}

.font-size-md {
  font-size: 1rem;
}

.paragraph-text {
  font-size: 1rem !important;
  font-family: var(--primary-font-family) !important;
  color: var(--secondary-text-color) !important;
  animation: fadeInAnimation ease 3s;
  // text-align: justify;
  // margin: auto;
  &--xs {
    font-size: 0.5rem;
    color: var(--secondary-text-color);
    // margin: auto;
    font-family: var(--primary-font-family);
  }

  @include desktop {
    font-size: 1.25rem !important;
    // margin-top: 1rem!important;

    &--xs {
      font-size: 1rem;
      color: var(--secondary-text-color);
      font-family: var(--primary-font-family);
    }
  }
}

.secondary-paragraph-text {
  font-size: 1rem !important;
  font-family: var(--primary-font-family);
  color: var(--primary-text-color);
  animation: fadeInAnimation ease 3s;

  // margin: auto;
  &--xs {
    font-size: 0.5rem;
    color: var(--secondary-text-color);
    margin: auto;
    font-family: var(--primary-font-family);
  }

  @include desktop {
    font-size: 1.25rem !important;
    &--xs {
      font-size: 1rem;
      color: var(--primary-text-color);
      // margin: auto;
      font-family: var(--primary-font-family);
    }
  }
}

.subtitle-text {
  font-size: 1.5rem;
  font-family: var(--bold-font-family) !important;
  color: var(--secondary-text-color) !important;
  animation: fadeInAnimation ease 3s;
}

.light-subtitle-text {
  font-size: 1.5rem;
  font-family: var(--primary-font-family);
  color: var(--secondary-text-color);
  vertical-align: middle;
  animation: fadeInAnimation ease 3s;
}

.primary-subtitle-text {
  font-size: 1.5rem;
  font-family: var(--bold-font-family);
  color: var(--primary-text-color);
  animation: fadeInAnimation ease 3s;
}
.secondary-subtitle-text {
  font-size: 1rem;
  font-family: var(--bold-font-family);
  color: var(--primary-text-color);
  text-overflow: ellipsis;
  animation: fadeInAnimation ease 3s;
  @include desktop {
    font-size: 1.5rem !important;
  }
}

.title-text {
  font-size: 2rem !important;
  font-family: var(--bold-font-family) !important;
  color: var(--secondary-text-color) !important;
  animation: fadeInAnimation ease 3s;
}
.secondary-title-text {
  font-size: 2rem !important;
  font-family: var(--bold-font-family) !important;
  color: var(--primary-text-color) !important;
  animation: fadeInAnimation ease 3s;
}

.light-title-text {
  font-size: 2rem;
  font-family: var(--primary-font-family);
  color: var(--secondary-text-color);
  animation: fadeInAnimation ease 3s;
}

.title-text-xl {
  font-size: 2.5rem;
  font-family: var(--bold-font-family);
  color: var(--secondary-text-color);
  vertical-align: middle;
  line-height: 5rem;
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
  animation: fadeInAnimation ease 3s;
}

.secondary-title-text {
  font-size: 2rem;
  font-family: var(--bold-font-family);
  color: var(--primary-text-color);
  animation: fadeInAnimation ease 3s;
  @include desktop {
    font-size: 2rem;
  }
}

.banner-title {
  font-size: 1rem;
  font-family: var(--bold-font-family);
  color: var(--primary-text-color);
  animation: fadeInAnimation ease 3s;
  @include desktop {
    font-size: 2rem;
  }
}

.highlight-title-xxl {
  font-size: 15rem;
  font-family: var(--primary-font-family);
  color: var(--primary-highlight-color);
  animation: fadeInAnimation ease 3s;
}

.highlight-title-xl {
  font-size: 3rem;
  font-family: var(--primary-font-family);
  color: var(--primary-highlight-color);
  animation: fadeInAnimation ease 3s;
  @include desktop {
    font-size: 6rem;
  }
}

.highlight-title-m {
  font-size: 1rem;
  font-family: var(--primary-font-family);
  color: var(--primary-highlight-color);
  animation: fadeInAnimation ease 3s;
  @include desktop {
    font-size: 2rem;
  }
}

.highlight-title-s {
  font-size: 1.5rem;
  font-family: var(--primary-font-family);
  color: var(--primary-highlight-color);
  animation: fadeInAnimation ease 3s;
}

.highlight-title-l {
  font-size: 2rem;
  font-family: var(--primary-font-family);
  color: var(--primary-highlight-color);
  animation: fadeInAnimation ease 3s;
  @include desktop {
    font-size: 3rem;
  }
}

.format-text {
  text-align: justify;
}

.pre-line {
  white-space: pre-line;
}
