.sidebar {
    position: fixed;
    z-index: 50;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(var(--light-primary-shadow-color), var(--light-secondary-shadow-color));
    opacity: 0;
    pointer-events: none;
    --translate-modal: 100%;
    transition: opacity 0.25s;
  
    &--show {
      opacity: 1;
      pointer-events: auto;
      --translate-modal: 0;
    }
  
    &--close {
      @include desktop {
        position: static;
        z-index: auto;
        background-image: none;
        opacity: 1;
        pointer-events: auto;
        --translate-modal: 0;
      }
    }
  }

  .panel-sidebar {
    z-index: 50;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    --translate-modal: 100%;
    transition: opacity 0.25s;
    opacity: 1;
    &--close {
      opacity: 1;
      pointer-events: auto;
      --translate-modal: 0;
    }
    position: static;
  }