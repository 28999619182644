.count-down 
    {
    font-size: 40px;
    border: 0.25rem solid var(--secondary-highlight-color);
    height: 11rem;
    width: 11rem!important;
    border-radius: 50%;
    font-family: var(--primary-font-family);
    margin: 1rem!important;
    box-shadow: 0.1rem 0rem 0.2rem 0rem var(--light-primary-shadow-color);
    &:hover
    {
        transform: translateY(0.25rem);
        filter: brightness(1);
        box-shadow: 0.1rem 0rem 0.2rem 0.2rem var(--secondary-highlight-color)
    }

    &--secondary {
        margin: 0rem!important;
        height: 12rem!important;
        width: 12rem!important;
    }
}

.count-down-box {
    border: 0.4rem solid var(--secondary-highlight-color);
    border-radius: 15px;
    padding: 1rem;
    filter: brightness(1);
    // padding: -12rem!important;
}

