.list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1.20rem;
    &--scroll {
      color: var(--primary-theme-color)!important;
      &__link {
        &::before {
          background-color: var(--primary-theme-color)!important;
        }
      }
    }

    &--sidebar {
        background-image: linear-gradient(to bottom, var(--light-primary-shadow-color), var(--secondary-text-color));
        // color: var(--dark-primary-text-color);
        width: 50%;
        height: 100%;
        box-sizing: border-box;
        padding: calc(var(--section-padding) * 0.5);
        transition: transform 0.5s;
    
        @include desktop {
          background-image: none;
        //   color: var(--primary-text-color);
          width: auto;
          padding: 0;
        }
      }
    
      &--left-sidebar {
        transform: translateX(calc(var(--translate-modal) * -1));
      }
    
      &--right-sidebar {
        margin-left: auto;
        transform: translateX(var(--translate-modal));
      }
      &__link {
        position: relative;
        color: var(--primary-text-color);
        font-weight: 600;
        text-decoration: none;
        transition: color 0.25s;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -0.5rem;
          width: 0;
          height: 0.3rem;
          background-color: var(--primary-text-color);
          transition: width 0.25s;
        }
        
        &:hover,
        &--active {
          color: var(--primary-text-color);
          &::before {
            width: 100%;
          
          }
        }

        @include desktop {
          color: var(--secondary-text-color);
          &::before {
            background-color: var(--secondary-text-color);
          }
          &:hover,
          &--active {
            color: var(--secondary-text-color);
          }
        }
      }
      
      &__element {
          padding-top: 0rem;
          padding-bottom: 0.75rem;
      }
}

.list-group {
  list-style: none; /* Remove default bullets */
  z-index: 50;
  font-size: 1rem;
  width: 100%;
  @include desktop {  
    font-size: 1rem;
  }


.list-item {
  &::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: var(--secondary-highlight-color); /* Change the color */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
 
}
}
  
.list-item{
  color: var(--secondary-text-color); /* Change the color */
  list-style: none; 
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
 
}

.list-group-item {
  cursor: pointer;
  border: none!important;
  border-radius: 0!important;

  &:hover{
    background-color: var(--secondary-text-color)!important;
    color: var(--primary-text-color)!important;
  }

  &.active{
    color: var(--primary-text-color)!important;
    background-color: var(--secondary-text-color)!important;
  }
}