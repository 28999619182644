.btn-primary {
  background-color: var(--secondary-highlight-color) !important;
  color: var(--primary-text-color) !important;
  border: none !important;
  font-size: 1.25rem !important;
  border-radius: 1rem !important;
  margin: 0 !important;
  padding: 1rem !important;
  font-family: var(--primary-font-family) !important;
}

.btn-secondary {
  background-color: transparent !important;
  color: var(--primary-text-color) !important;
  border: none !important;
  font-size: 1rem !important;
  border: none;
  margin: -5px;
  &:hover {
    filter: brightness(1);
    color: var(--secondary-highlight-color) !important;
  }
  @include desktop {
    margin-bottom: 0.5rem !important;
  }
}
