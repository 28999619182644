.gallery-modal {
  position:fixed!important;
  top: 0!important;
  left: 0!important;
  width: 100%!important;
  height: 100%!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  background-color: transparent!important;
}

.gallery-modal-content {
  position: relative;
  width: 47rem!important; /* Adjust the width as desired */
  height: auto!important; /* Adjust the height as desired */
  background-color: transparent!important;
  border-radius: 5px!important;
  @include desktop {
      width: 47rem!important;
   }
}

.close {
  float: right!important;
  cursor: pointer!important;
}

.modal-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50;
  color: var(--secondary-highlight-color);
  background-color: transparent;
  font-size: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  &:hover {
      filter: brightness(1);
      cursor: pointer;
    }
}